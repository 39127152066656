import Vue from 'vue';
import Router from 'vue-router';
import authentication from '../authentication';

// const x2IMode = process.env.VUE_APP_MODE || 'SCHEDULE';


Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      name: 'Home',
      path: '/',      
      meta: {
        requiresAuthentication: true,
      },
      component: () => import("../components/XerCleaner/XerUpload.vue")
    },
    {
      name: 'XerCleaner',
      path: '/XerCleaner',
      meta: {
        requiresAuthentication: true,
      },
      component: () => import("../components/XerCleaner/XerUpload.vue"),
    }   
  ],
});

// Global route guard
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuthentication)) {
    // this route requires auth, check if logged in
    if (authentication.isAuthenticated()) {
      // only proceed if authenticated.
      next();
    } else {
      authentication.signIn();
    }
  } else {
    next();
  }
});

export default router;
