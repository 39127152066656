import Vue from 'vue';
import axios, { AxiosStatic } from 'axios';

axios.defaults.baseURL = `https://${window.location.host}`;
Vue.prototype.$axios = axios;
declare module 'vue/types/vue' {
  interface Vue {
    $axios: AxiosStatic;
  }
}
