import Vue, { PluginObject } from 'vue';
import './plugins/axios'
import App from './App.vue';
import router from './router';
import authentication from './authentication';
import vuetify from './plugins/vuetify';
import './plugins/axios';

import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web'

const appInsights = new ApplicationInsights({ config: {
  instrumentationKey: 'ec7ec825-b03d-4619-ba8e-32bc4930f443',
  enableAutoRouteTracking: true
} });

Vue.config.productionTip = false;

// Init adal authentication - then create Vue app.
authentication.initialize().then(() => {
  const aiPlugin: PluginObject<void> = {
    install: (vue) => {
      const userInitializer = (item: ITelemetryItem): void => {
        const user = authentication.userPrincipal;
        if (user) {
          if (!item.data) {
            item.data = {};
          }
          item.data.UserId = user;
          item.data.UserName = user;
        }
      };
    
      appInsights.loadAppInsights();
      appInsights.addTelemetryInitializer(userInitializer);
      vue.prototype.$ai = appInsights;
    }
  };

  Vue.use(aiPlugin);

  new Vue({
    router,
    vuetify,
    render: (h) => h(App),
  }).$mount('#app');
});

declare module 'vue/types/vue' {
  interface Vue {
    $ai: ApplicationInsights;
  }
}