import { authModule, AuthModule } from "./auth";

export default {
  authenticationContext: null,
  userPrincipal: '',
  displayName: '',
  token: '',
  /**
   * @return {Promise}
   */
  async initialize(): Promise<void> {
    await authModule.loadAuthModule();
    if (!authModule.isAuthenticated) {

      authModule.login("loginRedirect");
    }
    else {
      console.log(authModule.isAuthenticated)
    }
  },
  /**
   * @return {Promise.<String>} A promise that resolves to an ADAL token for resource access
   */
  acquireToken(): Promise<string> {
    return new Promise((resolve, reject) => {

    });
  },
  /**
   * Issue an interactive authentication request for the current user and the api resource.
   */
  acquireTokenRedirect(): void {
    this.authenticationContext.acquireTokenRedirect(
      'api://430cb088-bfa3-460b-bf64-3fc6600c0821'
    );
  },
  /**
   * @return {Boolean} Indicates if there is a valid, non-expired access token present in localStorage.
   */
  isAuthenticated(): boolean {
    return authModule.isAuthenticated;
  },
  signIn(): void {
    authModule.login("loginRedirect")
  },
  signOut(): void {
    authModule.logout()
  }
};
